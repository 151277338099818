import React from "react"
import { Helmet } from "react-helmet";

/* Meta Data */
export default function MetaData({title, description, keyWords, authorName}) {
    return (
      <Helmet>
          { /* Standard metadata tags */ }
          <title>{title}</title>
          <meta charset="UTF-8" />
          <meta name="author" content={authorName} /> 
          <meta name='description' content={description} />
          <meta name='keywords' content={keyWords} />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          { /* End standard metadata tags */ }
          { /* Facebook tags */ }
          <meta property="og:type" content='Portfolio' />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          { /* End Facebook tags */ }
          { /* Twitter tags */ }
          <meta name="twitter:creator" content={authorName} />
          <meta name="twitter:card" content='Portfolio' />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          { /* End Twitter tags */ }
      </Helmet>
    )
}