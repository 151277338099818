import './App.css';
import './ldi/ldiRing.css';

import React, { useState, useEffect } from "react";
import sanityClient from "./Client";
import MetaData from "./MetaData";
import BlockContent from "@sanity/block-content-to-react";

import { IconContext } from "react-icons";
import { TbBrandBandcamp } from 'react-icons/tb';
import { RiSpotifyLine } from 'react-icons/ri';
import { SiDiscogs } from 'react-icons/si';
import { FaMixcloud } from 'react-icons/fa';
import { AiFillPhone } from 'react-icons/ai';
import { RiMailSendFill } from 'react-icons/ri';
import { SiBeatport } from 'react-icons/si';


export default function App() {

  /* Get the contents of the page */
  const [audibleContent, setAudibleContent] = useState(null);
  useEffect(() => {
      sanityClient.fetch(
          `*[_type == "siteSettings"][0] {
              APTitle,
              author,
              description,
              keyWords,
              publishedTracks,
              mixsetLink,
              imgjDescriptionTitle,
              imgjPresentation,
          }`            
      )
      .then((data) => setAudibleContent(data))
      .catch(console.error);
  }, []);      /* brackets makes the code run once */

  if (!audibleContent) return (<main><div class="ldi_Rng"><div></div><div></div><div></div><div></div></div>
  <br /><br /><br /></main>); // Load spinner

    return (
        <main>
            <MetaData 
                title={audibleContent.APTitle}
                description={audibleContent.description}
                keyWords={audibleContent.keyWords}
                authorName={audibleContent.author}
                />
            <div className="imgj_get_in_touch">
                    <IconContext.Provider value={{ className: 'iconStylingContact' }}>
                        <a href="http://pflo.gertjohnsen.media/#/contact" alt=""><RiMailSendFill /><AiFillPhone /></a>
                    </IconContext.Provider>       
            </div>
            <div className="imgj_links_by_icon">
                    <IconContext.Provider value={{ className: 'iconStylingGeneric' }}>
                        <a href="https://www.mixcloud.com/g4sen/" alt=""><FaMixcloud /></a>
                        <a href="https://imgj.bandcamp.com" alt=""><TbBrandBandcamp /></a>
                    </IconContext.Provider>
                    <IconContext.Provider value={{ className: 'iconStylingOverRide' }}>
                        <a href="https://www.beatport.com/artist/imgj/677515/tracks" alt=""><SiBeatport /></a>
                    </IconContext.Provider>
                    <IconContext.Provider value={{ className: 'iconStylingGeneric' }}>
                        <a href="https://open.spotify.com/artist/2DdwGq2KQQ9vUe3BmxUIBp?si=0Zwy9xzNSviU6AbdLORlWA" alt=""><RiSpotifyLine /></a>
                    </IconContext.Provider>
                    <IconContext.Provider value={{ className: 'iconStylingOverRide' }}>
                    <a href="https://www.discogs.com/artist/6459117-IMGJ" alt=""><SiDiscogs /></a>
                    </IconContext.Provider>       
            </div>
            <div className="imgj_info_container">
                <div>
                    <table>
                    <tr>
                        <td width="50%">
                            <h1>{audibleContent.imgjDescriptionTitle}</h1>
                            <BlockContent className='imgjText' blocks={audibleContent.imgjPresentation} projectId="ogu21hxe" dataset="production" />
                        </td>
                        <td width="50%">
                            {audibleContent.publishedTracks && audibleContent.publishedTracks.map ((myTunes, tuneIndex) => (
                                    <p><iframe title={tuneIndex} style={{border: '0', width: '100%', height: '120px'}} src={"https://bandcamp.com/EmbeddedPlayer/track=" + myTunes + "/size=large/bgcol=333333/linkcol=9D9A93/tracklist=false/artwork=small/transparent=true/"} seamless></iframe></p>
                                )) 
                            }
                        </td> 
                    </tr>
                    </table>
                </div>
            </div>
            <div className="imgj_mix_container">
                <iframe title="A Mix" width="96%" height="90vh" src={audibleContent.mixsetLink} frameborder="0" allow="autoplay"></iframe>
                <p></p>
            </div>
        </main>
    );
}

/*
<MetaData 
                title={landingContent.GJMTitle}
                description={landingContent.description}
                keyWords={landingContent.keyWords}
                authorName={landingContent.author}
                />

<IconContext>
    <TbBrandBandcamp color="white" fontSize="1.5em" /><RiSpotifyLine /><SiDiscogs /><FaMixcloud /><ImSoundcloud />
</IconContext>

*/